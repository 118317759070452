import React from 'react';

import { pieChartData } from '../../data/dummy';
import { ChartsHeader, Pie as PieChart } from '../../components';

const Pie = () => (
  <div className='m-4 md:m-10 mt-24 p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl'>
    {/* <ChartsHeader category='Pie' title='Project Cost Breakdown' /> */}
    <h1 className='pb-5 font-bold dark:text-gray-200'>
      Project Cost Breakdown
    </h1>

    <div className='w-full'>
      <PieChart
        id='chart-pie'
        data={pieChartData}
        legendVisiblity
        height='full'
      />
    </div>
  </div>
);

export default Pie;
